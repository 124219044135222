@keyframes activeAnimation {
  0% {
    transform: scale(0.5);
  }

  25% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes inactiveAnimation {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes fadeInAndOut {
  15% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  45% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes wiggle {
  10% {
    transform: translateX(-1vh);
  }

  25% {
    transform: translateX(1vh);
  }

  40% {
    transform: translateX(-1vh);
  }

  55% {
    transform: translateX(1vh);
  }

  70% {
    transform: translateX(0vh);
  }
}

@keyframes bounce {
  10% {
    transform: translateY(-1vh);
  }

  25% {
    transform: translateY(1vh);
  }

  40% {
    transform: translateY(-1vh);
  }

  55% {
    transform: translateY(1vh);
  }

  70% {
    transform: translateY(0vh);
  }
}

@keyframes emphasis {
  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
