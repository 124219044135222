html,
body {
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
}

* {
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
